<template>
  <div class="buy-rebate-order-page">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
      <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
        <el-input v-model="form.orderId" placeholder="请输入订单编号" style="width:200px;" clearable></el-input>

        <el-input v-model="form.customerTel" placeholder="请输入客户电话" style="width:160px;" clearable class="ml20"></el-input>
        <el-date-picker v-model="time" class="ml20" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <el-button type="primary" class="sure" @click="search">筛选</el-button>
      <el-button type="info" class="sure" @click="clear">清空</el-button>

      <PrevReclick>
        <el-button type="primary" class="sure" @click="exportExcle">导出</el-button>
      </PrevReclick>
    </div>
    <div class="orderDetail">
      <span>订单总笔数：{{orderNum}}</span>
      <span>订单总金额：{{orderAmount}}</span>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="orderId" label="订单编号" width="180"></el-table-column>

        <el-table-column prop="customerTel" label="客户电话"></el-table-column>
        <el-table-column prop="payId" label="支付订单编号"></el-table-column>
        <el-table-column prop="oriAmount" label="订单金额"></el-table-column>
        <el-table-column prop="payAmount" label="实际支付金额"></el-table-column>
        <el-table-column prop="createTime" label="支付时间" width="160">
          <template slot-scope="scope">
            <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payState" label="订单状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.payState == 0"> 未支付</span>
            <span v-else-if="scope.row.payState == 1"> 已支付 </span>
            <span v-else-if="scope.row.payState ==2">支付失败</span>
          </template>
        </el-table-column>
        <el-table-column prop="recName" label="推荐人名称"></el-table-column>
        <el-table-column prop="recIdentity" label="推荐人身份类型">
             <template slot-scope="scope">
             <span>{{recIdentityList[(scope.row.recIdentity)||0]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="recProfitAmount" label="推荐人分润(元)"></el-table-column>
        <el-table-column prop="ascAgentName" label="归属代理商"></el-table-column>
        <el-table-column prop="ascAgentProfitAmount" label="归属代理商分润(元)"></el-table-column>
        <el-table-column prop="plaProfitAmount" label="平台分润(元)"></el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import {
  $sg_CardOrderPage, $sg_marketingCardOrder, $sp_getPlatformOrderDetail,
} from '@/api/buyRebate';
import { exportFileSync } from '@base/utils';

const recIdentityList = ['', '平台', '分公司', '一级代理', '普通代理', '商户', '达人', '会员', '游客'];
export default {
  data() {
    return {
      form: {
        orderId: '',
        shopName: '',
        customerTel: '',
      },
      recIdentityList,
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      orderAmount: 0,
      orderNum: 0,
      orderVisible: false,
      orderDetail: {},
      shareProfitDtos: [],
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {

  },
  methods: {

    orderDetailResolve(item) {
      if (this.orderDetail.status === 4) {
        if (item.type === 'status') {
          return this.orderState[this.orderDetail.status];
        } else if (item.type === 'profitAmount') {
          return 0;
        } else {
          return this.orderDetail[item.type];
        }
      } else if (item.type === 'status') {
        return this.orderState[this.orderDetail.status];
      } else {
        return this.orderDetail[item.type];
      }
    },
    openDetail(item) {
      $sp_getPlatformOrderDetail({ orderId: item.orderId }).then((res) => {
        this.orderVisible = true;
        this.orderDetail = res || {};
        this.shareProfitDtos = res.shareProfitDtos;
      });
    },
    // 导出列表
    exportExcle() {
      let createTimeStart = null; let createTimeEnd = null;
      if (this.time) {
        createTimeStart = (this.time[0]).getTime() || null; createTimeEnd = (this.time[1]).getTime() || null;
      }

      exportFileSync(
        '/j/rebate/marketingCardOrder/export',
        {
          ...this.form,
          createTimeStart,
          createTimeEnd,
        },
        'post',
        '返利卡订单.xlsx',
        this.onDownloadProgress,
      ).then(() => {
        // this.handleDownloadQrCodeLeftBtn();
      });
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.currentPage = 1;
      this.form = {
        orderId: '',
        shopName: '',
        customerTel: '',
      };
      this.time = '';
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 15,
        ...this.form,
      };
      if (this.time) {
        params.createTimeStart = (this.time[0]).getTime();
        params.createTimeEnd = (this.time[1]).getTime();
      } else {
        params.createTimeStart = '';
        params.createTimeEnd = '';
      }
      // 请求订单数据
      $sg_CardOrderPage(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
      // 请求订单汇总
      $sg_marketingCardOrder(params).then((res) => {
        this.orderAmount = res.amount || 0;
        this.orderNum = res.total || 0;
      });
    },


    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" >
.buy-rebate-order-page {
  width: 100%;
  min-width: 1400px;
  // height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .longwidth {
    width: 300px !important;
  }
  .orderDetail {
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child {
      margin-right: 80px;
    }
  }
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table {
    // max-height: 400px;
    // overflow-y:auto;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .el-dialog__header {
    text-align: center;
  }
  .el-dialog__body {
    color: #444444;
    padding: 15px 20px;
    .el-table {
      max-height: 400px;
      overflow-y: auto;
      & > th {
        background-color: #ef3f46;
        color: #fff;
      }
    }
  }
  .order-detail-content {
    // display: flex;
    // flex-flow: wrap;
    // justify-content: space-between;
    padding: 0 20px;
    .shop-data {
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
    }
    .shop-data-li {
      width: 220px;
    }
    .share-profits {
      display: flex;
      flex-flow: wrap;
    }
    li {
      line-height: 30px;
      width: 190px;
    }
  }
}
</style>

